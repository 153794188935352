






































































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import DynamicFormData from '@/calendesk/models/DynamicFormData'
import { BookingDynamicFormTypes } from '@/calendesk/models/BookingDynamicFormTypes'
import TipTap from '@/lib/calendesk-js-library/components/TipTap.vue'
import { DynamicFormType } from '@/calendesk/models/BuilderTypes'
import { getServiceName } from '@/lib/calendesk-js-library/tools/helpers'

export default Vue.extend({
  name: 'DynamicFormElement',
  components: { TipTap },
  props: {
    element: {
      type: DynamicFormData
    },
    formType: {
      type: String,
      default: DynamicFormType.GENERAL
    },
    disallowSelectedServices: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      elementTypes: BookingDynamicFormTypes,
      type: BookingDynamicFormTypes.TEXT_FIELD as number | null,
      label: null as string | null,
      description: null as string | null,
      required: false,
      equal: null as any,
      isShowingMenu: false,
      selectedServices: null as Array<number> | null,
      radioOptions: [] as Array<string | number>,
      currentRadioOptionValue: null as string | null
    }
  },
  computed: {
    ...mapGetters({
      draftPages: 'builder/getDraftPages',
      services: 'service/getServices'
    }),
    getElementActivatorValue (): string {
      if (this.labelIsAvailable && this.label) {
        return this.label
      } else if (this.description) {
        return this.description
      }

      return this.$trans('wb_dynamic_form_element_type_' + (Number(this.type) + 1))
    },
    showServices (): boolean {
      return this.formType === DynamicFormType.BOOKING && !this.disallowSelectedServices
    },
    selectElementTypes (): Array<Record<string, number | string>> {
      const result = []
      if (this.elementTypes) {
        const types = Object.keys(BookingDynamicFormTypes).filter((key: any) => !isNaN(Number(BookingDynamicFormTypes[key])))
        for (const type in types) {
          result.push({
            text: this.$trans('wb_dynamic_form_element_type_' + (Number(type) + 1)),
            value: Number(type)
          })
        }
      }

      return result
    },
    labelIsAvailable (): boolean {
      const labelAvailable = [
        BookingDynamicFormTypes.TEXT_FIELD,
        BookingDynamicFormTypes.TEXT_AREA
      ]

      return labelAvailable.includes(this.type as number)
    }
  },
  watch: {
    element () {
      this.reload()
    }
  },
  created () {
    this.reload()
  },
  methods: {
    reload () {
      this.label = this.element.label
      this.description = this.element.description
      this.type = this.element.type
      this.required = this.element.required
      this.equal = this.element.equal
      this.selectedServices = this.element.services
      this.radioOptions = this.element.radioOptions || []
    },
    updateElement () {
      const data = new DynamicFormData(
        this.type as number,
        this.label as string,
        this.description as string,
        this.required,
        this.selectedServices,
        this.radioOptions,
        this.equal
      )

      this.$emit('change', data)
    },
    updateType (type: number) {
      this.type = type

      if (type !== this.elementTypes.RADIO) {
        this.radioOptions = []
        this.label = null
      }

      if (type !== this.elementTypes.TEXT_FIELD) {
        this.equal = null
      }

      if (type === BookingDynamicFormTypes.EMAIL) {
        this.label = this.$trans('email')
      }

      if (type === BookingDynamicFormTypes.PHONE) {
        this.label = this.$trans('phone')
      }

      this.updateElement()
    },
    deleteElement () {
      this.$emit('delete', true)
    },
    duplicateItem () {
      this.$emit('duplicate', true)
    },
    addRadioOption () {
      if (this.currentRadioOptionValue) {
        this.radioOptions?.push(this.currentRadioOptionValue)
        this.updateElement()
      }
    },
    removeRadioButton (index: number) {
      this.radioOptions.splice(index, 1)
      this.updateElement()
    },
    getServiceName
  }
})
