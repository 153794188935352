


















































































import Vue from 'vue'
import TipTap from '@/lib/calendesk-js-library/components/TipTap.vue'
import GdprFieldData from '@/calendesk/models/GdprFieldData'
export default Vue.extend({
  components: { TipTap },
  props: {
    element: {
      type: GdprFieldData
    }
  },
  data () {
    return {
      name: null as string | null,
      label: null as string | null,
      required: false,
      isShowingMenu: false
    }
  },
  watch: {
    element () {
      this.reload()
    }
  },
  created () {
    this.reload()
  },
  methods: {
    reload () {
      this.name = this.element.name
      this.label = this.element.label
      this.required = this.element.required
    },
    updateElement () {
      const data = new GdprFieldData(
        this.name as string,
        this.label as string,
        this.required
      )

      this.$emit('change', data)
    },
    deleteElement () {
      this.$emit('delete', true)
    }
  }
})
