














































































































































































































































import mixins from 'vue-typed-mixins'
import DraftActions from '@/builder/mixins/DraftActions'
import CalendeskImageSelectV2 from '@/components/Tools/CalendeskImageSelectV2.vue'
import { mapActions, mapGetters } from 'vuex'
import SectionImage from '@/calendesk/models/DTO/Response/SectionImage'
import SidebarDetails from '@/calendesk/models/SidebarDetails'
import { SidebarDetailsType } from '../sidebarDetails/SidebarDetailsType'
import { DraftActionsType } from '@/calendesk/models/DraftActionsType'
import GalleryInputV2 from '@/components/Tools/GalleryInputV2.vue'
import CItemsGallery from '@/components/Tools/CItemsGallery.vue'
import { DraftConfigurationType, DynamicFormType, SectionsSectionType } from '@/calendesk/models/BuilderTypes'
import { classToPlain, plainToClass } from 'class-transformer'
import CtaButtonData from '@/calendesk/models/CtaButtonData'
import CtaButtons from '@/components/Tools/CtaButtons/CtaButtons.vue'
import SectionFeatures from '@/components/Tools/SectionFeatures/SectionFeatures.vue'
import SectionsFeatureData from '@/calendesk/models/SectionsFeatureData'
import { SectionImageType } from '@/calendesk/models/DTO/Response/SectionImageType'
import SelectedGalleryImage from '@/calendesk/models/SelectedGalleryImage'
import { SectionImagePayload } from '@/calendesk/models/SectionImagePayload'
import CItemsGalleryActions from '@/builder/mixins/CItemsGalleryActions'
import CalendeskInformationMessage from '@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue'
import PagesSingleSelect from '@/builder/sidebar/sidebarDetails/components/PageSingleSelect.vue'
import CtaButton from '@/components/Tools/CtaButtons/components/CtaButton.vue'
import TipTap from '@/lib/calendesk-js-library/components/TipTap.vue'
import { BuilderSelectType } from '@/calendesk/models/BuilderSelectType'
import DefaultButtonsPositions from '@/calendesk/models/DefaultButtonsPositions'
import DefaultImagePositions from '@/calendesk/models/DefaultImagePositions'
import { AlignType } from '@/calendesk/models/AlignType'
import SimpleStoreActions from '@/builder/mixins/SimpleStoreActions'
import { RoundType } from '@/calendesk/models/RoundType'
import DefaultRoundTypes from '@/calendesk/models/DefaultRoundTypes'
import { GalleryStyleType } from '@/calendesk/models/GalleryStyleType'
import DefaultGalleryStyles from '@/calendesk/models/DefaultGalleryStyles'
import { DraftConfiguration } from '@/calendesk/models/DraftConfiguration'
import DefaultButtonSizeTypes from '@/calendesk/models/DefaultButtonSizeTypes'
import { ButtonSizeType } from '@/calendesk/models/ButtonSizeType'
import CTimePicker from '@/components/Tools/CTimePicker.vue'
import DynamicForm from '@/components/Tools/DynamicForm/DynamicForm.vue'
import DynamicFormData from '@/calendesk/models/DynamicFormData'
import Page from '@/calendesk/models/DTO/Response/Page'
import GdprFieldData from '@/calendesk/models/GdprFieldData'
import GdprFields from '@/components/Tools/GdprFields/GdprFields.vue'
import DefaultFormPositions from '@/calendesk/models/DefaultFormPositions'
import CalendarInput from '@/lib/calendesk-js-library/components/CalendarInput.vue'
import StaticPage from '@/calendesk/models/StaticPage'

export default mixins(DraftActions, CItemsGalleryActions, SimpleStoreActions).extend({
  name: 'Tools',
  components: {
    CalendarInput,
    GdprFields,
    DynamicForm,
    CTimePicker,
    TipTap,
    CtaButton,
    PagesSingleSelect,
    CalendeskInformationMessage,
    SectionFeatures,
    CtaButtons,
    CalendeskImageSelectV2,
    GalleryInputV2,
    CItemsGallery
  },
  data () {
    return {
      draftConfigurationType: DraftConfigurationType,
      sectionsSectionType: SectionsSectionType,
      tools: [
        DraftConfigurationType.DYNAMIC_FORM,
        DraftConfigurationType.TEXT,
        DraftConfigurationType.SECTION_FEATURES,
        DraftConfigurationType.LONG_TEXT,
        DraftConfigurationType.HTML_TEXT,
        DraftConfigurationType.GDPR_FIELDS,
        DraftConfigurationType.NUMBER,
        DraftConfigurationType.DATE,
        DraftConfigurationType.STYLE_HEIGHT,
        DraftConfigurationType.SELECT,
        DraftConfigurationType.CTA_BUTTON,
        DraftConfigurationType.ARRAY_CTA_BUTTONS,
        DraftConfigurationType.PAGE_IDS,
        DraftConfigurationType.PAGE_ID,
        DraftConfigurationType.EMPLOYEES,
        DraftConfigurationType.SERVICES,
        DraftConfigurationType.EXTERNAL_CONFIGURATION,
        DraftConfigurationType.ALERT,
        DraftConfigurationType.CHECKBOX,
        DraftConfigurationType.URL,
        DraftConfigurationType.TIME
      ] as Array<DraftConfigurationType>,
      mode: DraftActionsType.SECTION,
      imageTypes: SectionImageType,
      loading: false
    }
  },
  mounted () {
    const toLoad = []

    if (!this.employees) {
      toLoad.push(this.fetchEmployees())
    }

    if (!this.products) {
      toLoad.push(this.fetchProducts())
    }

    if (toLoad.length > 0) {
      Promise.all(toLoad)
    }
  },
  computed: {
    ...mapGetters({
      draftPages: 'builder/getDraftPages',
      getSidebar: 'sidebar/getSidebar'
    }),
    baseColors (): boolean {
      const invertCheckbox = this.configuration.find((config) => config.key === 'wb_invert_colors__checkbox__')

      if (invertCheckbox) {
        return !invertCheckbox.value
      }

      return true
    },
    draftPagesWithStaticPages (): (Page | StaticPage)[] {
      const staticPages = StaticPage.getAllStaticPages()

      return [...this.draftPages, ...staticPages]
    }
  },
  methods: {
    ...mapActions({
      openDialog: 'dialog/open',
      updateImage: 'builder/updateImage',
      removeImage: 'builder/removeImage',
      setExternalConfiguration: 'builder/setExternalConfiguration'
    }),
    isTextFieldNumberDisabledForConfig (config: DraftConfiguration) {
      return (config.key === 'wb_gallery_width__number__' && this.isMobile)
    },
    singleImageChangeHandle (selectedGalleryImage: SelectedGalleryImage, sectionImage: SectionImage): void {
      sectionImage.name = selectedGalleryImage.defaultImage.name

      this.updateImage({
        selectedGalleryImage,
        sectionImage
      } as SectionImagePayload)
    },
    internalImageChangeHandle (selectedGalleryImage: SelectedGalleryImage | null, sectionImage: SectionImage): void {
      if (selectedGalleryImage) {
        this.updateImage({
          selectedGalleryImage,
          sectionImage
        } as SectionImagePayload)
      } else {
        this.removeImage(sectionImage)
      }
    },
    createPortfolioImageHandle (selectedGalleryImage: SelectedGalleryImage, sectionImage: SectionImage): void {
      this.updateImage({
        selectedGalleryImage,
        sectionImage
      } as SectionImagePayload)
    },
    openSidebarDetailsExternalConfigurationHandle (id: number) {
      this.setExternalConfiguration(id)
      this.openSidebarDetails(new SidebarDetails(true, SidebarDetailsType.EXTERNAL_CONFIGURATION, {}))
    },
    openPagesSidebarDetails (key: string): void {
      this.openSidebarDetails(new SidebarDetails(true, SidebarDetailsType.PAGES_SELECT, { key }))
    },
    updateConfigurationForData <T> (data: Array<T>, key: string): void {
      this.updateConfigurationProperty(key, classToPlain(data), true)
    },
    generateArrayCtaButtonsData (value: object): Array<CtaButtonData> {
      return plainToClass(CtaButtonData, value as Array<CtaButtonData>)
    },
    generateArrayDynamicFormElementsData (value: object): Array<DynamicFormData> {
      return plainToClass(DynamicFormData, value as Array<DynamicFormData>)
    },
    generateArrayGdprFieldElementsData (value: object): Array<GdprFieldData> {
      return plainToClass(GdprFieldData, value as Array<GdprFieldData>)
    },
    generateArraySectionFeaturesData (value: object): Array<SectionsFeatureData> {
      return plainToClass(SectionsFeatureData, value as Array<SectionsFeatureData>)
    },
    getCtaButtonClassObject (value: object): CtaButtonData {
      return plainToClass(CtaButtonData, value)
    },
    getItemsForSelectType (type: BuilderSelectType): Array<Record<string, number>> {
      switch (type) {
        case BuilderSelectType.BUTTONS_POSITIONS:
          return DefaultButtonsPositions()
        case BuilderSelectType.FORM_POSITIONS:
          return DefaultFormPositions()
        case BuilderSelectType.IMAGE_POSITIONS:
          return DefaultImagePositions()
        case BuilderSelectType.IMAGE_ROUNDED:
        case BuilderSelectType.VIEW_ROUNDED:
          return DefaultRoundTypes()
        case BuilderSelectType.GALLERY_STYLE:
          return DefaultGalleryStyles()
        case BuilderSelectType.BUTTON_SIZE:
          return DefaultButtonSizeTypes()
      }

      return []
    },
    getTitleForSelectType (type: BuilderSelectType): string | null {
      switch (type) {
        case BuilderSelectType.BUTTONS_POSITIONS:
          return this.$trans('wb_buttons_position_title')
        case BuilderSelectType.FORM_POSITIONS:
          return this.$trans('wb_form_position_title')
        case BuilderSelectType.IMAGE_POSITIONS:
          return this.$trans('wb_image_position_title')
        case BuilderSelectType.IMAGE_ROUNDED:
          return this.$trans('wb_image_rounded')
        case BuilderSelectType.VIEW_ROUNDED:
          return this.$trans('wb_view_rounded')
        case BuilderSelectType.GALLERY_STYLE:
          return this.$trans('wb_gallery_style')
        case BuilderSelectType.BUTTON_SIZE:
          return this.$trans('wb_button_size_title')
      }

      return null
    },
    getObjectForSelectTypeAndValue (type: BuilderSelectType, value: AlignType | RoundType | GalleryStyleType | ButtonSizeType | string): Record<string, any> | string | null {
      switch (type) {
        case BuilderSelectType.BUTTONS_POSITIONS:
        case BuilderSelectType.IMAGE_POSITIONS:
        case BuilderSelectType.FORM_POSITIONS:
          return {
            text: this.getTextForAlignType(value as AlignType),
            value
          }
        case BuilderSelectType.IMAGE_ROUNDED:
        case BuilderSelectType.VIEW_ROUNDED:
          return {
            text: this.getTextForRoundType(value as RoundType),
            value
          }
        case BuilderSelectType.GALLERY_STYLE:
          return {
            text: this.getTextForGalleryStyleType(value as GalleryStyleType),
            value
          }
        case BuilderSelectType.BUTTON_SIZE:
          return {
            text: this.getTextForButtonSizeType(value as ButtonSizeType),
            value
          }
        case BuilderSelectType.PAGE_ID: {
          const page = this.draftPagesWithStaticPages.find((page: Page | StaticPage) => page.uuid === value)
          if (page) {
            return page.uuid
          }

          return null
        }
      }

      return null
    },
    getTextForAlignType (type: AlignType): string {
      switch (type) {
        case AlignType.TOP_LEFT:
          return this.$trans('wb_position_top_left_title')
        case AlignType.TOP_RIGHT:
          return this.$trans('wb_position_top_right_title')
        case AlignType.TOP_CENTER:
          return this.$trans('wb_position_top_center_title')
        case AlignType.BOTTOM_LEFT:
          return this.$trans('wb_position_bottom_left_title')
        case AlignType.BOTTOM_RIGHT:
          return this.$trans('wb_position_bottom_right_title')
        case AlignType.BOTTOM_CENTER:
          return this.$trans('wb_position_bottom_center_title')
      }
    },
    getTextForRoundType (type: RoundType): string {
      switch (type) {
        case RoundType.ZERO:
          return this.$trans('wb_round_zero_title')
        case RoundType.SM:
          return this.$trans('wb_round_sm_title')
        case RoundType.MD:
          return this.$trans('wb_round_md_title')
        case RoundType.LG:
          return this.$trans('wb_round_lg_title')
        case RoundType.XL:
          return this.$trans('wb_round_xl_title')
        case RoundType.PILL:
          return this.$trans('wb_round_pill_title')
        case RoundType.CIRCLE:
          return this.$trans('wb_round_circle_title')
      }
    },
    getTextForGalleryStyleType (type: GalleryStyleType): string {
      switch (type) {
        case GalleryStyleType.STYLE_1:
          return this.$trans('wb_gallery_style_1_title')
        case GalleryStyleType.STYLE_2:
          return this.$trans('wb_gallery_style_2_title')
        case GalleryStyleType.STYLE_3:
          return this.$trans('wb_gallery_style_3_title')
      }
    },
    getTextForButtonSizeType (type: ButtonSizeType): string {
      switch (type) {
        case ButtonSizeType.X_SMALL:
          return this.$trans('wb_button_size_type_x_small_title')
        case ButtonSizeType.SMALL:
          return this.$trans('wb_button_size_type_small_title')
        case ButtonSizeType.MEDIUM:
          return this.$trans('wb_button_size_type_medium_title')
        case ButtonSizeType.LARGE:
          return this.$trans('wb_button_size_type_large_title')
        case ButtonSizeType.X_LARGE:
          return this.$trans('wb_button_size_type_x_large_title')
      }
    },
    getDynamicFormTypeForKey (key: string): string {
      if (key === 'wb_booking_dynamic_form__dynamic_form__') {
        return DynamicFormType.BOOKING
      }

      return DynamicFormType.GENERAL
    }
  }
})
